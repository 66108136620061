import React, { useState } from 'react';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import CardManagementColumns from './components/CardManagementColumns';
import CardManagementFilters from './components/CardManagementFilters';
import { endpoint } from '../../AppGlobal';
import { useLocation } from 'react-router-dom';
import CardDetailsModal from './components/CardManagementDetailsModal';
import DrawerModal from '../../components/DrawerModal';
import AccountDetailsModal from '../AccountsPage/components/AccountsDetailsModal';
import { exportToExcel } from '../../const/exportToExcel';

function CardManagement() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const holderId = urlParams.get('holder_id');
    const [currentFilter, setCurrentFilter] = useState({});

    const fetchMethod = async (page, filter) => {
        if (holderId) {
            filter = { ...filter, holder_id: holderId };
        }
        const response = await endpoint.fetchCards(page, filter);
        
        return response;
    };

    const fetchExportMethod = async (filter) => {
    
        const response = await endpoint.fetchExportCards(filter);
    
        // Ensure we flatten the response data into a single array
        const flatResults = response.flatMap(chunk => chunk);
    
        const transformedData = flatResults.map(item => ({
            ...item,
            created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
            updated_at: new Date(item.updated_at).toLocaleDateString('en-GB'),
        }));
    
        return transformedData;
    };

    const hideSearch = !!holderId; // Condition to hide the search button if holderId exists

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [accountModalVisible, setAccountModalVisible] = useState(false);

    const openAccountModal = (account, processortype) => {
        setSelectedAccount(account, processortype);
        setAccountModalVisible(true);
    };

    const closeAccountModal = () => {
        setSelectedAccount(null);
        setAccountModalVisible(false);
    };

    const handleExport = async () => {
        const exportData = await fetchExportMethod(currentFilter);
        exportToExcel(exportData, 'Cards.xlsx');
    };

    return (
        <div>
            {/* {hideSearch ? (
                <CollapsedSearchLayout
                    fetchMethod={fetchMethod}
                    columns={CardManagementColumns(openModal, openAccountModal)} //remove openModal for the old details page
                    filters={CardManagementFilters}
                    barRoles={[]}
                    hideSearchButton={hideSearch}
                    title={"Card Management"}
                />
            ) : (
                <CollapsedSearchLayout
                    fetchMethod={fetchMethod}
                    columns={CardManagementColumns(openModal, openAccountModal)} //remove openModal for the old details page
                    filters={CardManagementFilters}
                    barRoles={[]}
                    title={"Card Management"} />
            )} */}
            <CollapsedSearchLayout
                fetchMethod={fetchMethod}
                columns={CardManagementColumns(openModal, openAccountModal)} //remove openModal for the old details page
                filters={CardManagementFilters}
                barRoles={[]}
                title={"Cards"}
                exportToExcel={handleExport}
                setCurrentFilter={setCurrentFilter}
                
            />
            {selectedItem && (
                <CardDetailsModal
                    visible={modalVisible}
                    card={selectedItem}
                    closeModal={closeModal}
                />
            )}
            {selectedAccount && (
                <AccountDetailsModal
                    visible={accountModalVisible}
                    account={selectedAccount}
                    closeModal={closeAccountModal}
                />
            )}
        </div>

        // For the old details page uncomment this div, comment above div.

        // <div>
        // {hideSearch ? (
        //     <CollapsedSearchLayout fetchMethod={fetchMethod} columns={CardManagementColumns} filters={CardManagementFilters} barRoles={[]} hideSearchButton={hideSearch} title={"Card Management"}/>
        // ) : (
        //     <CollapsedSearchLayout fetchMethod={fetchMethod} columns={CardManagementColumns} filters={CardManagementFilters} barRoles={[]} title={"Card Management"}/>
        // )}
        // </div>
    );
}

export default CardManagement;