import React, { useState } from 'react';
import { Layout, Pagination, Button } from 'antd';
import { FileExcelOutlined } from "@ant-design/icons";
import ProTable from '@ant-design/pro-table';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { createNotification } from '../stores/reducers/notificationreducer';
import { useDispatch } from 'react-redux';

function GenericTable({
  dataSource, loading, columns, pageSize = 0, currentPage,
  pageHandler = null, onChangeHandler = () => { },
  title, exportToExcel, exportButtonVisible = true
}) {
  const { Content } = Layout;
  const total = dataSource.count || 0;
  const [isExporting, setIsExporting] = useState(false);
  const dispatch = useDispatch();

  const responsiveTitles = (columns) => {
    return columns.map((item, index) => {
      item.key = 'columns_' + item.dataIndex + index;
      if (!item.customRender) {
        item.render = (title) => (
          <div>
            <div className='responsiveTableData'>{item.title}:</div>
            <div style={{ display: 'inline' }}>{title}</div>
          </div>
        );
      }
      return item;
    });
  };

  const handleExportClick = async () => {
    setIsExporting(true);

    try {
      await exportToExcel(dataSource.results);
      dispatch(createNotification({
        title: 'Success',
        message: 'Export completed successfully.',
        type: 'success'
      }));
    } catch (error) {

      dispatch(createNotification({
        title: 'Error',
        message: 'An error occurred during export.',
        type: 'error'
      }));
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <ConfigProvider locale={enUS}>
      <Content className='main-container'>
        <div className='tableContainer'>
          <h3>{title}</h3>
          <div style={{ marginBottom: '20px', textAlign: 'right' }}>
            {exportButtonVisible && (
              <Button
                onClick={handleExportClick}
                type="primary"
                icon={<FileExcelOutlined />}
                loading={isExporting}
              >
                Export
              </Button>
            )}
          </div>
          <ProTable
            bordered
            columns={responsiveTitles(columns)}
            dataSource={dataSource.results}
            loading={loading}
            rowKey={(record) => record.id}
            onChange={onChangeHandler}
            pagination={false}
            search={false}
            defaultSize='small'
            options={{ reload: false }}
            scroll={{ x: 'max-content' }}
          />
        </div>
        {pageSize > 0 && !loading && (
          <Pagination
            onChange={pageHandler || (() => { })}
            style={{ float: 'left', padding: '20px' }}
            defaultPageSize={pageSize}
            defaultCurrent={currentPage}
            total={total}
            showSizeChanger={false}
          />
        )}
      </Content>
    </ConfigProvider>
  );
}

export default GenericTable;
