import { useState } from 'react';
import { endpoint } from '../../AppGlobal';
import CardHoldersColumns from './components/CardHolderColumns';
import CardHolderFilters from './components/CardHolderFilters';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import { exportToExcel } from '../../const/exportToExcel';

function CardHolders() {

    const [currentFilter, setCurrentFilter] = useState({});

    const fetchMethod = async (page, filter) => {

        const response = await endpoint.fetchHolders(page, filter);

        return response;
    };

    const fetchExportMethod = async (filter) => {

        const response = await endpoint.fetchExportHolders(filter);

        // Ensure we flatten the response data into a single array
        const flatResults = response.flatMap(chunk => chunk);

        const transformedData = flatResults.map(item => ({
            ...item,
            created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
            updated_at: new Date(item.updated_at).toLocaleDateString('en-GB'),
            date_of_birth: new Date(item.date_of_birth).toISOString().split('T')[0]
        }));

        return transformedData;
    };

    const handleExport = async () => {
        const exportData = await fetchExportMethod(currentFilter);
        exportToExcel(exportData, 'Holders.xlsx');
    };

    return (
        <CollapsedSearchLayout fetchMethod={fetchMethod} columns={CardHoldersColumns} filters={CardHolderFilters} barRoles={[]} title={"Holders"} exportToExcel={handleExport} setCurrentFilter={setCurrentFilter} />

    );
}

export default CardHolders;