import React, { useState } from 'react'
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import AccountsColumns from './components/AccountsColumns';
import AccountsFilters from './components/AccountsFilters';
import { endpoint } from '../../AppGlobal';
import AccountDetailsModal from './components/AccountsDetailsModal';
import DrawerModal from '../../components/DrawerModal';
import { useLocation } from 'react-router-dom';
import { exportToExcel } from '../../const/exportToExcel';

function Accounts() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const holderId = urlParams.get('holder_id');
    const [currentFilter, setCurrentFilter] = useState({});

    const fetchMethod = async (page, filter) => {
        if (holderId) {
            filter = { ...filter, holder_id: holderId };
        }
        const response = await endpoint.fetchAccounts(page, filter);
        return response;
    };

    const fetchExportMethod = async (filter) => {

        const response = await endpoint.fetchExportAccounts(filter);

        // Ensure we flatten the response data into a single array
        const flatResults = response.flatMap(chunk => chunk);

        const transformedData = flatResults.map(item => ({
            ...item,
            available_balance: item.available_balance / 100,
            settled_balance: item.settled_balance / 100,
            reserved_balance: item.reserved_balance / 100,
            created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
        }));

        return transformedData;
    };

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    const handleExport = async () => {
        const exportData = await fetchExportMethod(currentFilter);
        exportToExcel(exportData, 'Accounts.xlsx');
    };

    return (

        <div>
            <CollapsedSearchLayout
                columns={AccountsColumns(openModal)} //remove openModal for the old details page
                fetchMethod={fetchMethod}
                filters={AccountsFilters}
                barRoles={[]}
                title={"Accounts"}
                exportToExcel={handleExport}
                setCurrentFilter={setCurrentFilter}
            />
            {selectedItem && (
                <AccountDetailsModal
                    visible={modalVisible}
                    account={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // For the old details page uncomment this, comment above div.

        // <CollapsedSearchLayout
        //     columns={AccountsColumns}
        //     fetchMethod={fetchMethod}
        //     filters={AccountsFilters}
        //     barRoles={[]}
        //     title={"Accounts"}
        // />
    )
}

export default Accounts