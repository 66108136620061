import React, { useState } from 'react';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import TransactionsAuthorizationsFilters from './components/TransactionAuthorizationsFilters';
import { endpoint } from '../../AppGlobal';
import TransactionsColumns from '../TransactionPage/components/TransactionsColumns';
import TransactionDetailsModal from '../TransactionPage/components/TransactionDetailsModal';
import DrawerModal from '../../components/DrawerModal';
import CardDetailsModal from '../CardManagementPage/components/CardManagementDetailsModal';
import { exportToExcel } from '../../const/exportToExcel';

function Authorizations() {

    const [currentFilter, setCurrentFilter] = useState({});

    const fetchMethod = async (page, filter) => {
        const response = await endpoint.fetchAuthorizations(page, filter);
        
        return response;
    };

    const fetchExportMethod = async (filter) => {
    
        const response = await endpoint.fetchExportAuthorizations(filter);
    
        // Ensure we flatten the response data into a single array
        const flatResults = response.flatMap(chunk => chunk);
    
        const transformedData = flatResults.map(item => ({
            ...item,
            auth_amount: item.auth_amount / 100,
            settle_amount: item.settle_amount / 100,
            trx_amount: item.trx_amount / 100,
            auth_date: new Date(item.auth_date).toLocaleDateString('en-GB'),
            settle_date: new Date(item.settle_date).toLocaleDateString('en-GB'),
            trx_date: new Date(item.trx_date).toLocaleDateString('en-GB'),
            created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
        }));
    
        return transformedData;
    };

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    const [selectedCard, setSelectedCard] = useState(null);
    const [cardModalVisible, setCardModalVisible] = useState(false);

    const openCardModal = (card_id, processor_type) => {
        setSelectedCard(card_id, processor_type);
        setCardModalVisible(true);
    };

    const handleExport = async () => {
        const exportData = await fetchExportMethod(currentFilter);
        exportToExcel(exportData, 'Authorizations.xlsx');
    };

    return (

        <div>
            <CollapsedSearchLayout
                columns={TransactionsColumns(openModal, openCardModal)}
                fetchMethod={fetchMethod}
                filters={TransactionsAuthorizationsFilters}
                barRoles={[]}
                title={"Authorizations"}
                exportToExcel={handleExport}
                setCurrentFilter={setCurrentFilter}
            />
            {selectedItem && (
                <TransactionDetailsModal
                    visible={modalVisible}
                    transaction={selectedItem}
                    closeModal={closeModal}
                />
            )}
            {selectedCard && (
                <CardDetailsModal
                    visible={cardModalVisible}
                    card={selectedCard}
                    closeModal={() => setCardModalVisible(false)}
                />
            )}
        </div>

        // <CollapsedSearchLayout
        //     columns={TransactionsColumns(openModal)}
        //     fetchMethod={fetchMethod}
        //     filters={TransactionsAuthorizationsFilters}
        //     barRoles={[]}
        //     title={"Authorizations"}
        // />
    )
}

export default Authorizations