import { useState } from 'react';
import { endpoint } from '../../AppGlobal';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import Role from '../../const/role';
import BalanceColumns from './components/BalanceColumns';
import BalanceFilters from './components/BalanceFilters';
import ProcessorDetailsModal from '../ClientManagement/components/ProcessorDetailsModal';
import DrawerModal from '../../components/DrawerModal';
import { exportToExcel } from '../../const/exportToExcel';

function BalanceManagement() {

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();
    const [currentFilter, setCurrentFilter] = useState({});

    const fetchExportMethod = async (filter) => {
    
        const response = await endpoint.fetchExportBalance(filter);
    
        // Ensure we flatten the response data into a single array
        const flatResults = response.flatMap(chunk => chunk);
    
        const transformedData = flatResults.map(item => ({
            ...item,
            created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
            updated_at: new Date(item.updated_at).toLocaleDateString('en-GB'),
        }));
    
        return transformedData;
    };

    const handleExport = async () => {
        const exportData = await fetchExportMethod(currentFilter);
        exportToExcel(exportData, 'Balance.xlsx');
    };

    return (

        <div>
            <CollapsedSearchLayout
                fetchMethod={endpoint.getMethod('fetchBalance')}
                columns={BalanceColumns(openModal)}
                filters={BalanceFilters}
                barRoles={[Role.BalanceEditor, Role.BalanceAdmin]}
                barAddURL="/balance/load"
                title={"Balance"}
                exportToExcel={handleExport}
                setCurrentFilter={setCurrentFilter}
            />
            {selectedItem && (
                <ProcessorDetailsModal
                    visible={modalVisible}
                    processor={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // <CollapsedSearchLayout 
        // fetchMethod={endpoint.getMethod('fetchBalance')} 
        // columns={BalanceColumns} 
        // filters={BalanceFilters} 
        // barRoles = {[Role.BalanceEditor, Role.BalanceAdmin]}
        // barAddURL = "/balance/load"
        // title={"Balance Management"}
        // />
    );
}

export default BalanceManagement;