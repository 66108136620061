import { useState } from 'react';
import { endpoint } from '../../AppGlobal';
import ClientColumns from './components/ClientColumns';
import ClientFilters from './components/ClientFilters';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import Role from '../../const/role';
import { exportToExcel } from '../../const/exportToExcel';

function ClientManagement() {
    const [currentFilter, setCurrentFilter] = useState({}); 

    const fetchMethod = async (page, filter) => {
        
        const response = await endpoint.fetchClients(page, filter);
        return response;
    };

    const fetchExportMethod = async (filter) => {
    
        const response = await endpoint.fetchExportClients(filter);
    
        // Ensure we flatten the response data into a single array
        const flatResults = response.flatMap(chunk => chunk);
    
        const transformedData = flatResults.map(item => ({
            ...item,
            master_account_balance: item.master_account_balance / 100,
            date_joined: new Date(item.date_joined).toLocaleDateString('en-GB'),
        }));
    
        return transformedData;
    };

    const handleExport = async () => {
        const exportData = await fetchExportMethod(currentFilter);
        exportToExcel(exportData, 'Clients.xlsx');
    };

    return (
       
        <CollapsedSearchLayout 
        fetchMethod={fetchMethod} 
        columns={ClientColumns} 
        filters={ClientFilters} 
        barRoles = {[Role.ClientEditor, Role.ClientAdmin]}
        barAddURL = "/clients/create"
        title={"Clients"}
        exportToExcel={handleExport}
        setCurrentFilter={setCurrentFilter}
        />
    );
}

export default ClientManagement;